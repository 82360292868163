'use client';

import React from 'react';

import { Box, Flex, Grid, GridItem, Heading, Image, Text } from '@chakra-ui/react';
import MaxWidthWrapper from '@/components/landing/MaxWidthWrapper';

export default function About() {
	return (
		<Box id="about-us" as="section" py={{ base: '64px', sm: '96px' }} bg="#F4FCF9" overflow="hidden">
			<MaxWidthWrapper>
				<Heading
					fontWeight={500}
					color="neutralDark"
					fontSize={{ base: '32px', sm: '48px' }}
					lineHeight="short"
					mb={16}
					textAlign="center"
					css={{
						textWrap: 'balance',
					}}
					as="h2"
				>
					Přiveďte{' '}
					<Text as="span" color="primaryDark.100">
						váš tým
					</Text>{' '}
					na&nbsp;správnou cestu!
				</Heading>

				<Flex flexDirection="column" rowGap="64px">
					<Grid
						templateColumns={{ base: '1fr', xl: '1fr 1fr' }}
						alignItems={{ base: 'start', xl: 'center' }}
						gap={{ base: '32px', sm: '32px 96px' }}
						zIndex={1}
						position="relative"
						_before={{
							content: '""',
							display: 'block',
							position: 'absolute',
							top: '10%',
							left: '-25%',
							w: '634px',
							h: '634px',
							bgColor: 'primaryLight',
							opacity: '0.3',
							zIndex: '-1',
							filter: 'blur(350px)',
							borderRadius: '100%',
						}}
					>
						<GridItem order={{ base: 2, xl: 1 }}>
							<Image src="./images/about-img-1.png" alt="Image" />
						</GridItem>

						<GridItem order={{ base: 1, xl: 2 }}>
							<Box maxW={{ base: '100%', md: '90%', xl: '632px' }} textAlign="start">
								<Heading
									as="h3"
									maxW={{ base: '100%', xl: '480px' }}
									fontSize={{ base: '28px', sm: '40px' }}
									fontWeight="500"
									mb={{ base: 2, sm: 4 }}
									lineHeight="short"
									color="neutralDark"
									fontFamily="gabarito"
									css={{
										textWrap: 'balance',
									}}
								>
									Nahraďte ruční evidenci{' '}
									<Text as="span" color="primaryDark.100">
										efektivním řešením
									</Text>
								</Heading>

								<Text
									maxW={{ base: '100%', md: '100%', xl: '100%' }}
									fontSize={{ base: 'md', md: 'lg' }}
									color="neutralDarkAlpha"
								>
									Eliminujte zbytečné chyby při{' '}
									<Text as="span" color="neutralDarkAlpha" fontWeight={700}>
										evidenci pracovních hodin
									</Text>{' '}
									a&nbsp;sledujte, jak váš{' '}
									<Text as="span" color="neutralDarkAlpha" fontWeight={700}>
										tým postupuje na&nbsp;zakázkách
									</Text>
									. Všechny klíčové informace budete mít{' '}
									<Text as="span" color="neutralDarkAlpha" fontWeight={700}>
										vždy na&nbsp;jednom místě
									</Text>
									, takže budete mít naprostý přehled o&nbsp;tom, kdo a&nbsp;kde pracuje.
								</Text>
							</Box>
						</GridItem>
					</Grid>

					<Grid
						templateColumns={{ base: '1fr', xl: '1fr 1fr' }}
						alignItems={{ base: 'start', xl: 'center' }}
						gap={{ base: '32px', sm: '32px 96px' }}
						zIndex={1}
						position="relative"
						_after={{
							content: '""',
							display: 'inline-block',
							position: 'absolute',
							bottom: '-75%',
							right: { base: '-550px', sm: '500px', md: '-40%' },
							w: '634px',
							h: '634px',
							opacity: '0.7',
							bgColor: 'secondaryLight',
							zIndex: '-1',
							filter: 'blur(350px)',
							borderRadius: '100%',
						}}
					>
						<GridItem order="2">
							<Image src="./images/about-img-2.png" alt="Image" />
						</GridItem>

						<GridItem order="1">
							<Box maxW={{ base: '100%', md: '90%', xl: '632px' }} textAlign="start">
								<Heading
									as="h3"
									maxW={{ base: '100%', xl: '100%' }}
									fontSize={{ base: '28px', sm: '40px' }}
									fontWeight="500"
									mb={{ base: '8px', sm: '16px' }}
									lineHeight="short"
									color="neutralDark"
									fontFamily="gabarito"
									w="100%"
								>
									Získejte{' '}
									<Text as="span" color="primaryDark.100">
										náskok díky přehledům
									</Text>
								</Heading>

								<Text
									maxW={{ base: '100%', md: '100%', xl: '100%' }}
									mb={{ base: '24px', sm: '32px' }}
									fontSize={{ base: 'md', md: 'lg' }}
									color="neutralDarkAlpha"
								>
									Sledujte, jak vaše firma funguje{' '}
									<Text as="span" color="neutralDarkAlpha" fontWeight={700}>
										v&nbsp;reálném čase
									</Text>
									. Mějte detailní přehled o&nbsp;
									<Text as="span" color="neutralDarkAlpha" fontWeight={700}>
										docházce, nákladech na&nbsp;zakázky a&nbsp;ziskovosti projektů
									</Text>
									. Zjistěte, kdo je největším tahounem týmu,{' '}
									<Text as="span" color="neutralDarkAlpha" fontWeight={700}>
										kdo přichází pozdě
									</Text>
									, a&nbsp;na&nbsp;kterých projektech váš tým aktuálně pracuje. Historická data vám
									pomohou odhalit trendy a&nbsp;
									<Text as="span" color="neutralDarkAlpha" fontWeight={700}>
										optimalizovat budoucí procesy
									</Text>
									. Vše snadno dostupné na&nbsp;jednom místě.
								</Text>
							</Box>
						</GridItem>
					</Grid>

					<Grid
						templateColumns={{ base: '1fr', xl: '1fr 1fr' }}
						alignItems={{ base: 'start', xl: 'center' }}
						gap={{ base: '32px', sm: '32px 96px' }}
						zIndex={1}
						position="relative"
					>
						<GridItem order={{ base: 2, xl: 1 }}>
							<Image src="./images/about-img-3.png" alt="Image" />
						</GridItem>

						<GridItem order={{ base: 1, xl: 2 }}>
							<Box maxW={{ base: '100%', md: '90%', xl: '632px' }} textAlign="start">
								<Heading
									as="h3"
									maxW="100%"
									w="100%"
									fontSize={{ base: '28px', sm: '40px' }}
									fontWeight={500}
									mb={{ base: '8px', sm: '16px' }}
									lineHeight="short"
									color="neutralDark"
									fontFamily="gabarito"
									css={{
										textWrap: 'balance',
									}}
								>
									<Text as="span" color="primaryDark.100">
										Týmová spolupráce
									</Text>{' '}
									bez hranic
								</Heading>

								<Text
									maxW={{ base: '100%', md: '100%', xl: '100%' }}
									mb={{ base: '24px', sm: '32px' }}
									fontSize={{ base: 'md', md: 'lg' }}
									color="neutralDarkAlpha"
								>
									Spolupracujte bez omezení, ať už jste kdekoliv. Přístup ke&nbsp;všem důležitým datům
									zaručuje plynulou a&nbsp;
									<Text as="span" color="neutralDarkAlpha" fontWeight={700}>
										efektivní práci na&nbsp;projektech
									</Text>
									, ať už jste v kanceláři, v&nbsp;terénu, nebo u&nbsp;klienta. Snadné sdílení úkolů
									usnadní organizaci a&nbsp;
									<Text as="span" color="neutralDarkAlpha" fontWeight={700}>
										zvýší produktivitu
									</Text>{' '}
									celého týmu.
								</Text>
							</Box>
						</GridItem>
					</Grid>
				</Flex>
			</MaxWidthWrapper>
		</Box>
	);
}
