import { MONTHLY_PRICE_PER_EMPLOYEE, YEARLY_PRICE_PER_EMPLOYEE } from '@/constants/pricing';

interface PricingResult {
	totalPrice: number;
	totalPriceWithoutDiscount: number;
	discount: number;
}

export const usePricing = (numberOfEmployees: number): PricingResult => {
	const calculateTotalPrice = (): PricingResult => ({
		totalPrice: numberOfEmployees * MONTHLY_PRICE_PER_EMPLOYEE,
		totalPriceWithoutDiscount: numberOfEmployees * YEARLY_PRICE_PER_EMPLOYEE,
		discount: numberOfEmployees * (MONTHLY_PRICE_PER_EMPLOYEE - YEARLY_PRICE_PER_EMPLOYEE) * 12,
	});

	return calculateTotalPrice();
};
