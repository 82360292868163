import { Box, Card, Flex, Image, Text } from '@chakra-ui/react';
import SolidBtn from '@/components/landing/buttons/SolidBtn';
import { EmployeeCounter } from './EmployeeCounter';

interface PricingCardProps {
	variant: 'trial' | 'premium' | 'enterprise';
	title: string;
	price?: string | number;
	priceWithoutDiscount?: string | number;
	pricePerEmployee?: string | number;
	pricePerEmployeeWithoutDiscount?: string | number;
	description: string;
	ctaText: string;
	ctaHref: string;
	ctaVariant?: 'light' | 'transparent' | 'solid' | 'outlined';
	ctaTarget?: string;
	showEmployeeCounter?: boolean;
	employeeCount?: string;
	onEmployeeCountChange?: (value: string) => void;
	isMonthly?: boolean;
	discount?: number;
}

function isNonPremiumVariant(variant: 'trial' | 'premium' | 'enterprise'): variant is 'trial' | 'enterprise' {
	return variant === 'trial' || variant === 'enterprise';
}

export function PricingCard({
	variant,
	title,
	price,
	priceWithoutDiscount,
	pricePerEmployee,
	pricePerEmployeeWithoutDiscount,
	description,
	ctaText,
	ctaHref,
	ctaVariant = 'solid',
	ctaTarget,
	showEmployeeCounter,
	employeeCount,
	onEmployeeCountChange,
	isMonthly,
	discount,
}: PricingCardProps) {
	return (
		<Card.Root
			p={12}
			w="full"
			h="fit-content"
			textAlign="center"
			justifyContent="center"
			display="flex"
			alignItems="center"
			borderColor="blackAlpha.100"
			rounded="2xl"
			shadow="2xl"
		>
			{variant === 'premium' ? (
				<Flex
					w="fit-content"
					fontWeight={500}
					mb={4}
					gap={2}
					px={3}
					py={2}
					bg="secondaryAlpha.10"
					rounded="full"
					alignItems="center"
				>
					<Image src="/images/hero_section_dashboard-bg.svg" alt="check" w={4} h="17.8px" />
					<Text fontFamily="gabarito" fontWeight="500" color="neutralDark">
						{title}
					</Text>
				</Flex>
			) : (
				<Text mb={3} color="neutralDark" fontWeight="500" fontFamily="gabarito" fontSize="xl">
					{title}
				</Text>
			)}

			{variant === 'premium' && pricePerEmployee && (
				<>
					<Flex gap={2.5} alignItems="center" mb={2}>
						<Text
							color="primaryDark.100"
							fontSize="2.5em"
							fontWeight="500"
							fontFamily="gabarito"
							lineHeight="short"
						>
							{pricePerEmployee} Kč
						</Text>
						{!isMonthly && pricePerEmployeeWithoutDiscount && (
							<Text
								color="fg.mediumContrast"
								fontSize="2em"
								fontWeight="500"
								fontFamily="gabarito"
								textDecoration="line-through"
							>
								{pricePerEmployeeWithoutDiscount} Kč
							</Text>
						)}
					</Flex>
					<Text mb={6} color="fg.mediumContrast" fontSize="base" fontWeight="500">
						měsíčně za zaměstnance
					</Text>
				</>
			)}

			{isNonPremiumVariant(variant) && price && (
				<Flex gap={2.5} alignItems="center" mb={2}>
					<Text color="primaryDark.100" fontSize="1.75em" fontWeight="500" fontFamily="gabarito">
						{price} Kč
					</Text>
					{!isMonthly && priceWithoutDiscount && (
						<Text
							color="fg.mediumContrast"
							fontSize="1.75em"
							fontWeight="500"
							fontFamily="gabarito"
							textDecoration="line-through"
						>
							{priceWithoutDiscount} Kč
						</Text>
					)}
				</Flex>
			)}

			{isNonPremiumVariant(variant) && (
				<Text mb={6} fontSize="14px" fontWeight="500">
					{description}
				</Text>
			)}

			{showEmployeeCounter && employeeCount && onEmployeeCountChange && (
				<EmployeeCounter value={employeeCount} onChange={onEmployeeCountChange} />
			)}

			{variant === 'premium' && price && (
				<>
					<Text mb={2} color="fg.mediumContrast" fontSize="base" fontWeight="500">
						Celkem za měsíc
					</Text>
					<Flex gap={2} alignItems="center" mb={4}>
						<Text color="primaryDark.100" fontSize="1.5em" fontWeight="500" fontFamily="gabarito">
							{price} Kč
						</Text>
						{!isMonthly && priceWithoutDiscount && (
							<Text
								color="fg.mediumContrast"
								fontSize="1.25em"
								fontWeight="500"
								fontFamily="gabarito"
								textDecoration="line-through"
							>
								{priceWithoutDiscount} Kč
							</Text>
						)}
					</Flex>
				</>
			)}

			{discount && (
				<Box
					w="fit-content"
					fontWeight={500}
					mb={6}
					px={4.5}
					py={1}
					bg="bg.yellowAccentAlpha"
					rounded="full"
					alignItems="center"
				>
					<Text fontWeight="500" fontSize="xs" color="fg.yellowText">
						{!isMonthly
							? `🔥 ušetříte ${discount} Kč s ročním plánem`
							: `💡 s ročním plánem byste ušetřili ${discount} Kč`}
					</Text>
				</Box>
			)}

			<SolidBtn
				content={ctaText}
				width={{ base: 'full', sm: variant === 'premium' ? 'full' : 'fit-content' }}
				href={ctaHref}
				variant={ctaVariant}
				target={ctaTarget}
				isCenter
			/>

			{variant === 'premium' && (
				<Text fontSize="sm" fontWeight="500" mt={3}>
					Uvedené ceny jsou bez DPH.
				</Text>
			)}
		</Card.Root>
	);
}
