import { Box, Flex, Text } from '@chakra-ui/react';
import { Button } from '@/components/ui/button';

interface BillingToggleProps {
	isMonthly: boolean;
	onToggle: (isMonthly: boolean) => void;
}

export function BillingToggle({ isMonthly, onToggle }: BillingToggleProps) {
	return (
		<Box>
			<Text fontWeight="500" color="neutralDark" textAlign="center" mb={4}>
				Získejte{' '}
				<Text as="span" color="primaryDark.100" fontWeight="700">
					15% slevu
				</Text>{' '}
				s ročním plánem
			</Text>
			<Flex bg="white" p={3} rounded="xl">
				<Button
					onClick={() => onToggle(false)}
					bg={isMonthly ? 'transparent' : 'primaryDark.100'}
					w={{ base: '130px', sm: '180px' }}
					rounded="lg"
					display="flex"
					gap={2.5}
					color={isMonthly ? 'neutralDark' : 'white'}
				>
					Ročně{' '}
					<Box
						px={2}
						py={1}
						bg={isMonthly ? 'primaryDarkAlpha.10' : 'whiteAlpha.300'}
						rounded="2xl"
						color={isMonthly ? 'primaryDark.100' : 'white'}
					>
						-15 %
					</Box>
				</Button>
				<Button
					w={{ base: '130px', sm: '180px' }}
					rounded="lg"
					bg={isMonthly ? 'primaryDark.100' : 'transparent'}
					color={isMonthly ? 'neutralLight' : 'neutralDark'}
					onClick={() => onToggle(true)}
				>
					Měsíčně
				</Button>
			</Flex>
		</Box>
	);
}
