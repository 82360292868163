'use client';

import { Box, Flex, Grid, GridItem, Heading, Image, Stack, Text } from '@chakra-ui/react';

import SolidBtn from './buttons/SolidBtn';
import MaxWidthWrapper from './MaxWidthWrapper';

export default function CallToActionWithAnnotation() {
	return (
		// TODO: Make backgound wrapper from this box from header and this hero, si it have share backgound

		// <Flex w="100%" alignItems="center" justifyContent="center" pt="76px">
		<Box
			as="section"
			bg="red.500"
			w="100%"
			position="relative"
			bgColor="neutralLight"
			overflow="hidden"
			// bgColor="rgb(10, 15, 13, 0.80)"
		>
			<Box
				_before={{
					content: '""',
					display: 'block',
					position: 'absolute',
					top: '-30%',
					left: '-3%',
					w: '634px',
					h: '634px',
					bgColor: 'primaryLight',
					opacity: '0.3',
					zIndex: '-1',
					blur: '1000px',
					borderRadius: '100%',
				}}
				_after={{
					content: '""',
					display: 'inline-block',
					position: 'absolute',
					bottom: '-14%',
					right: { base: '-550px', sm: '500px', md: '-14%' },
					w: '634px',
					h: '634px',
					opacity: '0.7',
					bgColor: 'secondaryLight',
					blur: '1000px',
					borderRadius: '100%',
				}}
			/>
			<Box
				w="100%"
				pt={{ base: '96px', md: '148px', '2xl': '160px' }}
				pb={{ base: '64px', md: '140px', '2xl': '160px' }}
				backdropFilter="blur(1000px)"
			>
				<MaxWidthWrapper>
					<Grid
						gap={16}
						templateColumns={{ base: '1fr', xl: '1fr 1.1fr' }}
						justifyItems={{ base: 'center', xl: 'unset' }}
						alignItems="center"
					>
						<GridItem>
							<Flex
								textAlign={{ base: 'center', xl: 'left' }}
								alignItems={{ base: 'center', xl: 'flex-start' }}
								direction="column"
								gap={{ base: 6, md: 8 }}
							>
								<Stack gap={{ base: 4, md: 6 }} width="100%" maxWidth="70ch">
									<Flex
										m={{ base: '0 auto', xl: '0' }}
										alignItems="center"
										gap={2}
										px={{ base: 3, sm: 4 }}
										py={2}
										w="fit"
										bg="secondaryAlpha.10"
										rounded="4xl"
									>
										<Image
											src="images/checkmark.svg"
											w={{ base: 2, sm: '10px' }}
											h={{ base: 3, sm: '14px' }}
											alt="Checkmark"
										/>{' '}
										<Text as="span" color="blackAlpha.800" fontSize={{ base: 'xs', sm: 'md' }}>
											Pro realizační týmy, průmysl, služby a&nbsp;další...
										</Text>
									</Flex>

									{/* TODO - Add breackpoint to the textWrap */}
									<Heading
										fontWeight={500}
										color="neutralDark"
										fontSize={{ base: '32px', md: '48px' }}
										lineHeight="short"
										css={{
											'@media (max-width: 1280px)': {
												textWrap: 'balance',
											},
										}}
										as="h1"
									>
										Získejte{' '}
										<Text as="span" color="primaryDark.100">
											přehled o&nbsp;docházce
										</Text>{' '}
										a&nbsp;nákladech na&nbsp;zakázkách za&nbsp;pár minut!
									</Heading>
									<Text
										mx="auto"
										color="neutralDarkAlpha"
										fontSize={{ base: 'base', md: 'xl' }}
										css={{ textWrap: 'pretty' }}
									>
										Taskgrip vám umožní jednoduše sledovat docházku, náklady a&nbsp;efektivitu
										vašeho týmu na&nbsp;každé zakázce - vše v&nbsp;reálném čase.
									</Text>
								</Stack>
								<Flex gap={4} flexWrap="wrap">
									<SolidBtn
										content="Vyzkoušet ZDARMA"
										width={{ base: '100%', sm: 'fit-content' }}
										href="/login"
									/>
									<SolidBtn
										content="Naplánovat schůzku"
										variant="transparent"
										width={{ base: '100%', sm: 'fit-content' }}
										href="https://calendly.com/filip-macoun-taskgrip/taskgrip"
										target="_blank"
									/>
								</Flex>
							</Flex>
						</GridItem>

						<GridItem position="relative">
							<Image w="100%" src="/images/hero_section_dashboard.png" alt="Hero image" />
						</GridItem>
					</Grid>
				</MaxWidthWrapper>
			</Box>
		</Box>
	);
}
