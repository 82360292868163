'use client';

import React from 'react';
import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import MaxWidthWrapper from '@/components/landing/MaxWidthWrapper';
import { MONTHLY_PRICE_PER_EMPLOYEE, YEARLY_PRICE_PER_EMPLOYEE } from '@/constants/pricing';
import { usePricing } from '@/hooks/usePricing';
import { BillingToggle } from '../pricing/BillingToggle';
import { PricingCard } from '../pricing/PricingCard';

export default function PricingTable() {
	const [isMonthly, setIsMonthly] = React.useState(false);
	const [numberOfEmployees, setNumberOfEmployees] = React.useState('1');
	const { totalPrice, totalPriceWithoutDiscount, discount } = usePricing(Number(numberOfEmployees));

	return (
		<Box id="prices" as="section" py={{ base: '64px', sm: '96px' }} pt={{ base: 0, sm: '96px' }} bg="#F4FCF9">
			<MaxWidthWrapper isFull>
				<Flex
					py={24}
					px={{ base: 0, md: 16 }}
					flexDir="column"
					gap="16"
					justifyContent="center"
					alignItems="center"
					bgImage="url('/images/prices-bg.jpg')"
					bgRepeat="no-repeat"
					bgSize="cover"
					rounded={{ base: 'none', md: '2xl' }}
				>
					<Flex flexDir="column" gap="12" alignItems="center">
						<Heading
							fontWeight={500}
							color="neutralDark"
							fontSize={{ base: '32px', sm: '48px' }}
							lineHeight="short"
							textAlign="center"
							css={{
								textWrap: 'balance',
							}}
							as="h2"
						>
							Změna na&nbsp;dosah ruky. <Text color="primaryDark.100">Stačí jen udělat první krok.</Text>
						</Heading>

						<BillingToggle isMonthly={isMonthly} onToggle={setIsMonthly} />
					</Flex>

					<Grid
						w={{ base: 'calc(100% - 12vw)', md: 'full' }}
						templateColumns={{ base: '1fr', xl: '1fr 1fr 1fr' }}
						alignItems="center"
						gap={{ base: 8, '2xl': 16 }}
					>
						<PricingCard
							variant="trial"
							title="Zkušební na 14 dní"
							description="Pro zkušební verzi není vyžadována platební karta."
							ctaText="Zahájit test"
							ctaHref="/login"
							ctaVariant="light"
						/>

						<PricingCard
							variant="premium"
							title="Premium"
							pricePerEmployee={isMonthly ? MONTHLY_PRICE_PER_EMPLOYEE : YEARLY_PRICE_PER_EMPLOYEE}
							pricePerEmployeeWithoutDiscount={!isMonthly ? MONTHLY_PRICE_PER_EMPLOYEE : undefined}
							price={isMonthly ? totalPrice : totalPriceWithoutDiscount}
							priceWithoutDiscount={!isMonthly ? totalPrice : undefined}
							description={isMonthly ? 'měsíčně za zaměstnance' : 'ročně za jednoho zaměstnance'}
							ctaText="Předplatit"
							ctaHref="/login"
							showEmployeeCounter
							employeeCount={numberOfEmployees}
							onEmployeeCountChange={setNumberOfEmployees}
							isMonthly={isMonthly}
							discount={discount}
						/>

						<PricingCard
							variant="enterprise"
							title="Enterprise"
							description="Pro firmy s 50+ zaměstnanci připravíme cenově výhodné řešení na míru."
							ctaText="Naplánovat schůzku"
							ctaHref="https://calendly.com/filip-macoun-taskgrip/taskgrip"
							ctaTarget="_blank"
							ctaVariant="light"
						/>
					</Grid>
				</Flex>
			</MaxWidthWrapper>
		</Box>
	);
}
