import { Box, HStack, IconButton, NumberInput, Text } from '@chakra-ui/react';
import { FiMinus, FiPlus } from '@shared/icons';

interface EmployeeCounterProps {
	value: string;
	onChange: (value: string) => void;
}

export function EmployeeCounter({ value, onChange }: EmployeeCounterProps) {
	return (
		<Box w="full" borderY="1px solid" borderColor="fg.border" py={4} mb={4}>
			<NumberInput.Root unstyled value={value} onValueChange={(e) => onChange(e.value)} min={1} max={999}>
				<HStack gap={3} justifyContent="center">
					<HStack gap={0}>
						<NumberInput.DecrementTrigger asChild>
							<IconButton
								size="sm"
								borderRadius="full"
								color="fg.primaryText"
								backgroundColor="#E6F4EF"
								aria-label="Decrease employees"
							>
								<FiMinus />
							</IconButton>
						</NumberInput.DecrementTrigger>
						<NumberInput.ValueText
							width={10}
							textAlign="center"
							textStyle="lg"
							minW="3ch"
							color="fg.normalContrast"
							fontSize="0.9375em"
							fontWeight="500"
						/>
						<NumberInput.IncrementTrigger asChild>
							<IconButton
								size="sm"
								borderRadius="full"
								color="fg.primaryText"
								backgroundColor="#E6F4EF"
								aria-label="Increase employees"
							>
								<FiPlus />
							</IconButton>
						</NumberInput.IncrementTrigger>
					</HStack>
					<Text color="fg.mediumContrast" fontSize="sm" fontWeight="500">
						zaměstnanců
					</Text>
				</HStack>
			</NumberInput.Root>
		</Box>
	);
}
